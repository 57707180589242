










import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import ModalView from '@/components/helper/modal-view.vue';
import FormBase from '@/components/helper/form-base.vue';
import Util from '@/lib/util'
import url from '@/lib/url'
import Schedule from '@/store/entities/courses/schedule';
import ListBase from '@/lib/listbase';

@Component({
    components: { FormBase, ModalView },
    watch: {
        value: function (val) {
            let obj = (this as any);
            obj.modalShow = val;
            if (val) {
                obj.schedule = Util.extend(true, {}, this.$store.state.schedule.editModel);
                obj.initForm();
            } else
                this.$emit('input', false);
        },
        modalShow: function (val) {
            if (!val) this.$emit('input', false);
        }
    }
})

export default class ScheduleForm extends ListBase {
    entity: string = 'schedule';
    @Prop({ type: Boolean, default: false }) value: boolean;
    schedule: Schedule = new Schedule();
    modalShow: boolean = false;
    modalSetting: any = {
        entity: "schedule",
        title: 'Horario',
        width: '700px',
        showForm: false,
    };
    
    get isEditing() {
        return this.schedule.id > 0;
    }

    items = [
        //this.gItem({ type: 'object', prop: 'fee_id', label: 'Tarifa por defecto', size: 4, hint: '', persistentHint: '', onchange: this.changeFee}),
        this.gItem({ type: 'text', prop: 'name', label: 'Nombre', required: true, size: 12 }),
        this.gItem({ type: 'time', prop: 'start_hour', label: 'Hora de Incio', hint: 'HH:MM', persistentHint: true, size: 4 }),
        this.gItem({ type: 'time', prop: 'end_hour', label: 'Hora de Fin', hint: 'HH:MM', persistentHint: true, size: 4 }),
        this.gItem({ type: 'active', label: 'Estado', size: 4 }),
        this.gItem({ type: 'radio', prop: 'nro_marks', label: 'Modalidad de Marcado de asistencia', size: 12, options: 'mark_types', row: true }),            
        this.gItem({ type: 'subheader', subheader: 'Dias de atencion'}),
        this.gItem({ type: 'checkboxlist', prop: 'days_list', label: '', multiple: true, options: 'week_days', size: 12, row: true }),
    ];

    changeFee(id) {
        if (id == null)
            this.setItem(this.items, 'fee_id', 'hint', '');
        else {
            let data = Util.getEntity(this, 'fee', id);
            let sign = Util.abp.setting.get('DEFAULT_CURRENCY_SIGN');
            this.setItem(this.items, 'fee_id', 'hint', sign + ' ' + data.amount);
        }
    }

    initForm() {
        if (this.isEditing)
            this.changeFee(this.schedule.fee_id);
    }

    async save() {
        if ((this.$refs.entityForm1 as any).validate()) {
            let model = Util.extend(true, {}, this.schedule);

            await this.$store.dispatch({
                type: this.entity + '/' + (this.schedule.id > 0 ? 'update' : 'create'),
                data: model
            });
            this.$emit('save-success');
            this.cancel();
            if (this.schedule.id > 0)
                Util.abp.notify.success(this, 'Horario actualizado!');
            else
                Util.abp.notify.success(this, 'Horario registrado!');

            this.$store.commit('schedule/setActiveList', []);
        }
    }

    cancel() {
        this.$emit('input', false);
        (this.$refs.entityForm1 as any).reset();
    }

    mounted() {

    }

    created() {
    }
}
