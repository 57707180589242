































import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import url from '@/lib/url'
import ListBase from '@/lib/listbase';
import PageRequest from '@/store/entities/page-request';
import ListView from '@/components/helper/list-view.vue';
import FormBase from '@/components/helper/form-base.vue';
import CourseForm from './course-form.vue';

class PageCourseRequest extends PageRequest {
    code: string = '';
    name: string = '';
}

@Component({
    components: { ListView, FormBase, CourseForm }
})
export default class Courses extends ListBase {
    entity: string = 'course';
    pagerequest: PageCourseRequest = new PageCourseRequest();
    filterForm: boolean = true;

    edit() {
        this.modalShow = !this.modalShow;
    }
    create() {
        this.modalShow = !this.modalShow;
    } 

    async afterSave() {
        this.createModalShow = false;
        let list: any = this.$refs.listView
        await list.getpage();
    }

    filterItems = [
        this.gItem({ type: 'text', prop: 'code', label: 'Buscar por Codigo', size: 2, onchange: this.afterSave, hideDetails: true }),
        this.gItem({ type: 'text', prop: 'name', label: 'Descripcion de Curso', size: 4, onchange: this.afterSave, hideDetails: true }),
        this.gItem({ type: 'spacer', size: 6 }),
    ]

    columns = [
        this.gColO({ type: 'text', value: 'code', text: 'Codigo', main: true }),
        this.gCol('name', 'Nombre del Curso', 'text'),
        this.gCol('duration_text', 'Duracion', 'text'),
        this.gCol('active', 'Habilitado', 'text'),
        this.defaultActions()
    ];

    imageSrc(image) {
        return url + image;
    }

    async created() {
        //this.getpage();            
    }
}