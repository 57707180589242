





















import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import ModalView from '@/components/helper/modal-view.vue';
import FormBase from '@/components/helper/form-base.vue';
import Util from '@/lib/util'
import url from '@/lib/url'
import Course from '@/store/entities/courses/course';
import ListBase from '@/lib/listbase';
import Schedule from '@/views/courses/schedule/schedule.vue';

@Component({
    components: { FormBase, ModalView, Schedule }, 
    watch: {
        value: function (val) { 
            let obj = (this as any);
            obj.modalShow = val;
            if (val) {
                obj.course = Util.extend(true, {}, this.$store.state.course.editModel); 
                obj.initForm();
            } else 
                this.$emit('input', false);
        },
        modalShow: function (val) {
            if (!val) this.$emit('input', false);
        }
    }
})

export default class EditCourseForm extends ListBase {
    entity: string = 'course';
    @Prop({ type: Boolean, default: false }) value: boolean;
    course: Course = new Course();
    modalShow: boolean = false;
    uploading: boolean = false;
    image: any = null;
    modalSetting: any = {
        entity: "course",
        title: 'Curso',
        width: '800px',
        showForm: false,
    };
    
    get loading() {
        return this.$store.state[this.entity].loading;
    };

    get imageUrl() {
        return (this.image != null ? this.image :
            (this.course.image_url != null ? url + this.course.image_url : null));
    }

    get isEditing() {
        return this.course.id > 0;
    }
                 
    items = [
        this.gItem({ type: 'text', prop: 'code', label: 'Codigo', required: true, size: 4, icon: 'cached', readonly: true, appendEvent: this.getLastCode, onenter: this.getLastCode }),
        this.gItem({ type: 'text', prop: 'name', label: 'Nombre', required: true, size: 8 }),
        this.gItem({ type: 'text', prop: 'description', label: 'Descripcion', size: 12 }),
        //this.gItem({ type: 'radio', prop: 'duration_type', label: 'Tipo de Duracion', defaultValue: '0', options: 'duration_types', row: true, size: 9 }),
        this.gItem({ type: 'number', prop: 'duration', label: 'Duracion', size: 4, required: true, prefixSelect: true, prefixProp: 'duration_type', options: 'duration_types' }),
        this.gItem({ type: 'number', prop: 'academic_hours', label: 'Horas Academicas', size: 4, required: true, suffix: 'Horas' }),
        this.gItem({ type: 'active', label: 'Estado', size: 4 }),
        this.gItem({ type: 'divider', subheader: 'Tarifas', size: 12 }),
        this.gItem({ type: 'object', prop: 'fee_public_id', label: 'Tarifa para Publico en General', size: 4, required: true, entity: 'fee', itemText: 'description', hideDetails: true }),
        this.gItem({ type: 'object', prop: 'fee_lawyer_id', label: 'Tarifa para Agremiados', size: 4, required: true, entity: 'fee', itemText: 'description', hideDetails: true }),
        this.gItem({ type: 'object', prop: 'fee_lawyer_old_id', label: 'Tarifa para Agremiados mayores a 60 anios', size: 4, required: true, entity: 'fee', itemText: 'description', hideDetails: true }),
    ];

    openImage() {
        (this.$refs.fileInput as any).click();
    }

    onFileChange() {
        let ipt = (this.$refs.fileInput as any);
        if (ipt.files == undefined) return;
        if (ipt.files.length > 0) {
            if (!ipt.files.length)
                return;
            let file = ipt.files[0];

            let reader = new FileReader();
            let vm = this;
            reader.onload = (e) => {
                vm.image = (e as any).target.result;
            };
            reader.readAsDataURL(file);
        }
    }
    
    async getLastCode() {
        let code = await this.$store.dispatch({
            type: 'course/getLastCode'
        });
        this.course.code = code;
    };
    
    initForm() {
        this.image = null;
        if (!this.isEditing) {
            this.getLastCode();
        } else {
            this.$store.commit('schedule/setCourse', this.course.id);
            this.$store.commit('schedule/setList', []);

            if (this.$refs.sched != undefined)
                (this.$refs.sched as any).afterSave();
        }
    }

    async save() {
        if ((this.$refs.entityForm1 as any).validate()) {
            let model = Util.extend(true, {}, this.course); 
            if (this.image != null)
                model.imageData = this.image;

            await this.$store.dispatch({
                type: this.entity + '/' + (this.course.id > 0 ? 'update' : 'create'),
                data: model
            });
            this.$emit('save-success');
            this.cancel();
            if (this.course.id > 0)
                Util.abp.notify.success(this, 'Informacion del Curso actualizada!');
            else
                Util.abp.notify.success(this, 'El Curso ha sido creado!');
        } 
    }        

    cancel() {
        this.$emit('input', false);
        (this.$refs.entityForm1 as any).reset();
    }

    mounted() {       

    }

    created() {
    }
}
