

















import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import PageRequest from '@/store/entities/page-request';
import Schedule from '@/store/entities/courses/schedule';
import ListView from '@/components/helper/list-view.vue';
import ScheduleForm from './schedule-form.vue';

class PageScheduleRequest extends PageRequest { }

@Component({
    components: { ListView, ScheduleForm }

})
export default class Schedules extends ListBase {
    pagerequest: PageScheduleRequest = new PageScheduleRequest();
    schedule: Schedule = new Schedule();
    viewModalShow: boolean = false;
    modalSetting: any = {
        entity: "schedule",
        title: 'Horario',
        width: "500px",
        showForm: false
    };

    edit() {
        this.modalShow = !this.modalShow;
    }

    create() {
        this.modalShow = !this.modalShow;
    }

    async afterSave() {
        let list: any = this.$refs.listView
        await list.getpage();
    }

    columns = [
        //this.gCol('fee.name', 'Tarifa', 'text'),
        this.gCol('name', 'Nombre', 'text'),
        this.gCol('days', 'Dias de Atencion', 'text'),
        this.gCol('hours', 'Horario', 'text'),
        this.gCol('active', 'Habilitado', 'text'),
        this.defaultActions('edit|delete', 100)
    ]

    async created() {
        //this.getpage();
    }
}